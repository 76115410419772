import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MaterialTable, { MTableToolbar } from 'material-table';

import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { useStyles } from '../../styles';

import { UserData } from 'services/dataService';
import { localizationTable, options } from 'constants/table';
import { Search } from '@material-ui/icons';
import GamificationData from 'services/dataService/GamificationData';
import { useSelector } from 'react-redux';

const SolicitationStatus = {
  pending: 1,
  approved: 2,
  reproved: 3,
};

export default () => {
  const [name, setName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filter, setFilter] = useState('');
  const [queryStatus, setQueryStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const checkedRows = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [qtdPoints, setQtdPoints] = useState(0);
  const [observations, setObservations] = useState(0);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();
  const classes = useStyles();

  const { id } = useSelector(
    (state) => state.auth
  );

  function formatarDataIntl(isoString) {
    return new Intl.DateTimeFormat('pt-BR').format(new Date(isoString));
  }

  const handleSetUserId = (id) => {
    setSelectedUserId(id);
  };

  async function getAllTransactions(id) {
    const transactionsResponse = await GamificationData.getAllTransactions({ "id_user": id });
    const { data } = transactionsResponse;
    setTransactions(data);
  }

  async function handleSubmitForm(event, data) {
    event.preventDefault();

    const gamificationTransactionResponse = await GamificationData.createTransaction({
      amount: qtdPoints,
      type: data.type,
      observations: observations,
      id_user: data.id_user,
      id_admin: id
    })
    if (gamificationTransactionResponse.status === 200 && gamificationTransactionResponse.data.data.message === "Success") {
      setIsModalOpen(!isModalOpen);
      setFeedbackMessage("Transação processada com sucesso");
      setTimeout(() => {
        setFeedbackMessage(null);
        window.location.reload();
      }, 2000)
    }
  }

  const handleSubmitFilter = (data) => {
    setFilter(data.filter);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <div className='notranslate'>
      {
        feedbackMessage && (
          <div className={classes.modal}>
            <div className={classes.feedbackMessageContainer}>
              <p>{feedbackMessage}</p>
            </div>
          </div>
        )
      }
      {
        isModalOpen && (
          <>
            <div className={classes.modal}>
              {
                modalType === "add" && (
                  <div className={classes.modalContent}>
                    <div className={classes.modalHeader}>
                      <h1 className={classes.modalHeaderTitle}>Adicionar pontos</h1>
                      <div className={classes.modalClose}>
                        <a onClick={() => setIsModalOpen(!isModalOpen)}>
                          <span className={classes.modalCloseIcon}>✖</span>
                        </a>
                      </div>
                    </div>
                    <div className={classes.modalContainer}>
                      <form className={classes.modalForm} onSubmit={(event) => handleSubmitForm(event, { type: "CREDIT", id_user: selectedUserId })}>
                        <input type='number' onChange={(e) => setQtdPoints(e.target.value)} className={classes.modalFormInput} placeholder='Quantidade' />
                        <textarea type='text' onChange={(e) => setObservations(e.target.value)} className={classes.modalFormInput} placeholder='Observações' />
                        <button type='submit' className={classes.modalFormButton}>Adicionar</button>
                      </form>
                    </div>
                  </div>
                )
              }
              {
                modalType === "remove" && (
                  <div className={classes.modalContent}>
                    <div className={classes.modalHeader}>
                      <h1 className={classes.modalHeaderTitle}>Remover pontos</h1>
                      <div className={classes.modalClose}>
                        <a onClick={() => setIsModalOpen(!isModalOpen)}>
                          <span className={classes.modalCloseIcon}>✖</span>
                        </a>
                      </div>
                    </div>
                    <div className={classes.modalContainer}>
                      <form className={classes.modalForm} onSubmit={(event) => handleSubmitForm(event, { type: "DEBIT", id_user: selectedUserId })}>
                        <input type='text' onChange={(e) => setQtdPoints(e.target.value)} className={classes.modalFormInput} placeholder='Quantidade' />
                        <textarea type='text' onChange={(e) => setObservations(e.target.value)} className={classes.modalFormInput} placeholder='Observações' />
                        <button type='submit' className={classes.modalFormButton}>Remover</button>
                      </form>
                    </div>
                  </div>
                )
              }
              {
                modalType === "stats" && (
                  <div className={classes.modalContent}>
                    <div className={classes.modalHeader}>
                      <h1 className={classes.modalHeaderTitle}>Ver Extrato</h1>
                      <div className={classes.modalClose}>
                        <a onClick={() => setIsModalOpen(!isModalOpen)}>
                          <span className={classes.modalCloseIcon}>✖</span>
                        </a>
                      </div>
                    </div>
                    <div className={classes.modalStatsContainer}>
                      <table className={classes.modalStatsTable}>
                        <tr className={classes.modalStatsTableHeader}>
                          <th>Descrição</th>
                          <th></th>
                          <th>Data</th>
                          <th>Pontos</th>
                        </tr>
                        {
                          transactions && transactions.map((transaction, index) => (
                            <tr className={classes.modalStatsTableRow} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f7f9fc', padding: 10 }}>
                              <td>{transaction.observations}</td>
                              <td>{transaction.type === 'CREDIT' ? <div className={classes.rowStatusTypeCredit}></div> : <div className={classes.rowStatusTypeDebit}></div>}</td>
                              <td>{formatarDataIntl(transaction.createdAt)}</td>
                              <td>{transaction.amount}</td>
                            </tr>
                          ))
                        }
                      </table>
                    </div>
                  </div>
                )
              }
            </div>
          </>
        )
      }
      <MaterialTable
        title={`${t('Gestão de Pontos')}`}
        tableRef={tableRef}
        columns={[
          {
            title: `${t('Nome')}`,
            field: `name`,
            sorting: false,
            render: (rowData) => rowData.name,
          },
          {
            title: `${t('E-mail')}`,
            sorting: false,
            field: 'email',
            render: (rowData) => rowData.email,
          },
          {
            title: `${t('CPF')}`,
            field: 'cpf',
            sorting: false,
            render: (rowData) => rowData.cpf,
          },
          {
            title: `${t('Pontos')}`,
            field: 'user_score',
            sorting: false,
            render: (rowData) =>
              rowData.score,
          },
        ]}
        data={async (query) => {
          try {
            const userResponse = await UserData.getUsers({
              page: query.page + 1,
              limit: query.pageSize,
              filter,
            });
            const users = userResponse.data.users.filter(
              user => user.group && user.group.type === 2
            );

            const coinsResponse = await GamificationData.getAllUserCoins(); // retorna todas as moedas
            const { data: { allUserCoins } } = coinsResponse;

            // map para associar id_user ao total de moedas
            const coinsMap = new Map(allUserCoins.map(coin => [coin.id_user, coin.total_coins]));

            // combinar usuários com suas moedas
            const usersWithCoins = users.map(user => ({
              ...user,
              score: coinsMap.get(user.id) || 0, // Adiciona a pontuação correta ou 0
            }));

            setIsLoading(false);

            return {
              data: usersWithCoins,
              page: userResponse.data.currentPage - 1,
              totalCount: userResponse.data.totalCount,
            };
          } catch (err) {
            if (err.response?.status === 403) {
              toast.error(t('toastify:commons.toast_error_403'));
            } else {
              toast.error(t('toastify:commons.toast_error_api'));
            }
            setIsLoading(false);

            return { data: [] };
          }
        }}
        components={{
          search: true,
          title: false,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(handleSubmitFilter)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:commons.input_search')}
                        name="filter"
                        type="text"
                        variant="outlined"
                        defaultValue={filter || ''}
                        inputRef={register}
                      />
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        type="submit"
                      >
                        <Search style={{ marginRight: '5px' }} />
                        {t('button:commons.btn_advanced_filter')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        actions={[
          {
            icon: 'add',
            tooltip: `Adicionar`,
            position: 'row',
            onClick: (event, rowData) => {
              event.preventDefault();
              setIsModalOpen(!isModalOpen);
              setModalType("add");
              handleSetUserId(rowData.id);
            },
            iconProps: { color: 'primary' },
          },
          {
            icon: 'remove',
            tooltip: `Remover`,
            position: 'row',
            onClick: (event, rowData) => {
              event.preventDefault();
              setIsModalOpen(!isModalOpen);
              setModalType("remove");
              handleSetUserId(rowData.id);
            },
            iconProps: { color: 'primary' },
          },
          {
            icon: 'equalizer',
            tooltip: `Ver Extrato`,
            position: 'row',
            onClick: (event, rowData) => {
              event.preventDefault();
              setIsModalOpen(!isModalOpen);
              setModalType("stats");
              handleSetUserId(rowData.id);
              getAllTransactions(rowData.id);
            },
            iconProps: { color: 'primary' },
          },
        ]}
        isLoading={isLoading}
        localization={localizationTable(t)}
        options={options}
      />
    </div>

  );
};
