import React from 'react';

const GamificationRewards = () => {

    return (
        <div>
            <h1>Gestão de Recompensas</h1>
        </div>
    )
}

export default GamificationRewards;