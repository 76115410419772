import React from 'react';
import PointsTable from './components/PointsTable';

const GamificationPointsManagment = () => {

    return (
        <div>
            <PointsTable />
        </div>
    )
}

export default GamificationPointsManagment;