export const ONLY_INVITED_USERS = '@company/set_only_invited_users_register';
export const ACCESS_REQUEST = '@company/set_access_request';
export const DOMAIN_COMPANY = '@company/domain_company';
export const CHANGE_COMPANY_THEME = '@company/change_theme';
export const SHOW_ANSWERS = '@company/show_answers';
export const SHOW_TRAILS = '@company/show_trails';
export const SHOW_LIBRARY = '@company/show_library';
export const SHOW_PRESENCIAL = '@company/show_presencial';
export const SHOW_GAMIFICATION = '@company/show_gamification';

export function setOnlyInviteUsers(payload) {
  return {
    type: ONLY_INVITED_USERS,
    payload,
  };
}
export function setAccessRequest(payload) {
  return {
    type: ACCESS_REQUEST,
    payload,
  };
}
export function setShowAnswers(payload) {
  return {
    type: SHOW_ANSWERS,
    payload,
  };
}
export function setShowLibrary(payload) {
  return {
    type: SHOW_LIBRARY,
    payload,
  };
}
export function setShowPresencial(payload) {
  return {
    type: SHOW_PRESENCIAL,
    payload,
  };
}
export function setShowTrails(payload) {
  return {
    type: SHOW_TRAILS,
    payload,
  };
}
export function setShowGamification(payload) {
  return {
    type: SHOW_GAMIFICATION,
    payload,
  };
}
export function setDomainCompany(payload) {
  return {
    type: DOMAIN_COMPANY,
    payload,
  };
}
export function changeCompanyTheme(payload) {
  return {
    type: CHANGE_COMPANY_THEME,
    payload,
  };
}
