import React from 'react';

const GamificationRanking = () => {

    return (
        <div>
            <h1>Ranking</h1>
        </div>
    )
}

export default GamificationRanking;