import { Divider, Drawer, Hidden } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Toolbar from '@material-ui/core/Toolbar';
import {
  ArrowBackIos,
  BookmarkBorder,
  BookmarkOutlined,
  DesktopWindows,
  Dvr,
  EventOutlined,
  SchoolOutlined
} from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { ProfileType } from 'constants/types';
import useWindowDimensions from 'customHooks/DimensionHook';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PublicCoursesViewsData from 'services/dataService/PublicCoursesViewsData';
import enablesCompaniesArray from '../../../../helpers/enablesCompanies';
import enablesCompaniesArrayPresencial from '../../../../helpers/enablesCompaniesPresencial';
import adminRoutes from '../../../../routes/admin.routes';
import userRoutes from '../../../../routes/user.routes';
import { Profile, SidebarNav } from './components';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 240,
      width: '25%',
      flexShrink: 0,
    },
  },
  toolbar: {
    overflow: 'auto',
    /*overflow: 'hidden',
    paddingRight:11,
    "&:hover": {
      overflow: 'auto'
    },*/
    [theme.breakpoints.up('md')]: {
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#c5c5c5',
        borderRadius: '8px',
      },
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  drawerPaper: {
    maxWidth: 240,
    minWidth: 165,
    width: '25%',
  },
  mobileDrawer: {
    minWidth: 220,
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginTop: 10,
  },
  headerSidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
  },
  linkLogo: {
    display: 'flex',
  },
  logo: {
    maxWidth: 166,
    objectFit: 'contain',
    maxHeight: 40,
  },
}));

const Sidebar = (props) => {
  const { type, pendent_attendance } = useSelector((state) => state.auth);
  const { open, onClose } = props;
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const [lectureItems, setLectureItems] = useState([]);
  const { width } = useWindowDimensions();
  const { t } = useTranslation(['title']);
  const routes = {
    1: [
      ...adminRoutes,
      {
        title: t('title:Attendance.title_create'),
        href: 'https://tawk.to/chat/5fc7ae13920fc91564cccdf3/1esl3c4j8',
        icon: (
          <Badge badgeContent={pendent_attendance} color="primary">
            <Dvr />
          </Badge>
        ),
      },
    ],
    2: userRoutes,
  };
  const { company } = useSelector((state) => state);
  const [customization, setCustomization] = useState({});
  const [currentRoutes, setCurrentRoutes] = useState(routes[type]);

  const loadTopbarStyles = async () => {
    try {
      const { data: customization } =
        await PublicCoursesViewsData.getCompanyCustomization(
          company?.domain_company
        );
      setCustomization(customization?.company_header);
    } catch (error) { }
  };

  useEffect(() => {
    loadTopbarStyles();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/lecture')) {
      {
        const modulesExample = [
          {
            id: 'f124d4a0-5aba-48b5-b583-2b76620366cc',
            title: 'Módulo 1',
            description: 'Módulo 1',
            order: 0,
            lessons: [
              {
                id: 'baa3e723-7c8b-45a3-bf14-c05a10d5d9c7',
                title: 'Aula 1',
                description: 'Aula 1',
                order: 0,
                status: 2,
              },
              {
                id: '33d78317-42d9-4702-b6d3-b7eb2963018c',
                title: 'Aula 2',
                description: 'Aula 2',
                order: 1,
                status: 1,
              },
            ],
          },
          {
            id: 'f124d4a0-5aba-4825-b583-2b76620366cc',
            title: 'Módulo 2',
            description: 'Módulo 2',
            order: 1,
            lessons: [
              {
                id: 'baa3e723-7c8b-42a3-bf14-c05a10d5d9c7',
                title: 'Aula 1',
                description: 'Aula 1',
                order: 0,
                status: 1,
              },
              {
                id: '33d78317-42d9-4202-b6d3-b7eb2963018c',
                title: 'Aula 2',
                description: 'Aula 2',
                order: 1,
                status: 1,
              },
            ],
          },
        ];
        const itemsBar = [
          {
            title: 'Voltar',
            href: '/student-content',
            icon: <ArrowBackIos />,
          },
          {
            title: 'Nome do curso',
            href: '#',
            icon: <DesktopWindows />,
          },
          {
            title: `Curso disponível até:${'20/04/2020'} `,
            href: '#',
            icon: <EventOutlined />,
          },
        ];
        const modulesBar = modulesExample.map((module) => {
          const subitems = module.lessons.map((lesson) => {
            return {
              title: lesson.title,
              href: '#',
              icon:
                lesson.status === 1 ? <BookmarkBorder /> : <BookmarkOutlined />,
            };
          });

          return {
            title: module.title,
            href: '#',
            icon: <SchoolOutlined />,
            subitems,
          };
        });

        setLectureItems([...itemsBar, ...modulesBar]);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    let newRoutes = [...routes[type]];
  
    const canShowTrailInSidebar = company?.show_trails
    const canShowPresencialInSidebar = company?.show_presencial;
    const canShowGamificationInSidebar = company?.show_gamification;
    const canShowLibraryInSidebar = company?.show_library;



    if(!canShowGamificationInSidebar){
      if (type === ProfileType.ADMIN) {
        newRoutes = newRoutes.filter(menu => menu.title.props?.text !== 'Gamificação');
        setCurrentRoutes(newRoutes);
      } 
      
      if (type === ProfileType.STUDENT) {
        newRoutes = newRoutes.filter(item => item.href !== '/score');
        setCurrentRoutes(newRoutes);
      }

    }

    if (type === ProfileType.ADMIN) {
      if (company?.domain_company !== '5eaa01a3-4506-4ebb-a23f-e2288e874600') {
        const removeSubPlatf = 1;
        const removeEmailSub = 1;
        newRoutes[0].subitems.splice(removeSubPlatf, 1);
        newRoutes[4].subitems.splice(removeEmailSub, 1);
      }
      if (company?.domain_company === '4b44b51e-c4d0-4209-9fcb-1db56f09b036') {
        const indexToRemove = 6;       
        newRoutes[indexToRemove].hidden = true;
      }
    }

    if (type === ProfileType.STUDENT) {
      if (company?.domain_company === '4b44b51e-c4d0-4209-9fcb-1db56f09b036') {
        const indexToRemove = 3; 
        newRoutes[indexToRemove].hidden = true;
      }
    }

    if(!canShowPresencialInSidebar){
      if (type === ProfileType.ADMIN) {
        const removePresencialHref = '/classroomcourses/manage';
        const removerReportPresencialFromAdminsMenuxIndex = 4

        newRoutes[2].subitems = newRoutes[2].subitems.filter(item => item.href !== removePresencialHref);
        newRoutes[3].subitems.splice(removerReportPresencialFromAdminsMenuxIndex, 1);
      }
    }

    if(!canShowLibraryInSidebar){
      if (type === ProfileType.ADMIN) {
        const removeLibraryHref = '/library';
        newRoutes[2].subitems = newRoutes[2].subitems.filter(item => item.href !== removeLibraryHref);
      }

      if (type === ProfileType.STUDENT) {
          const indexToRemove = 2; 
          newRoutes[indexToRemove].hidden = true;
      }
    }

    if (!canShowTrailInSidebar) {
      if (type === ProfileType.ADMIN) {
        const removeTrailsHref = '/trails';
        const removerReportTrailsFromAdminsMenuxIndex = 3
        newRoutes[2].subitems = newRoutes[2].subitems.filter(item => item.href !== removeTrailsHref);
        newRoutes[3].subitems.splice(removerReportTrailsFromAdminsMenuxIndex, 1);

      }

      if (type === ProfileType.STUDENT) {
        const removeTrailIndex = newRoutes.findIndex(
          (route) => route.title === 'Trilhas disponíveis'
        );
        if (removeTrailIndex !== -1) {
          newRoutes.splice(removeTrailIndex, 1);
        }
      }
      
      setCurrentRoutes(newRoutes);
    }
    //console.log('newRoutes', newRoutes)

  }, [company]);

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={onClose}
          classes={{
            paper: clsx(
              classes.drawerPaper,
              width < 600 && classes.mobileDrawer
            ),
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {location.pathname.includes('/lecture') ? (
            <div className={classes.toolbar}>
              <SidebarNav className={classes.nav} pages={lectureItems} />
            </div>
          ) : (
            <div className={classes.toolbar}>
              {width < 600 && (
                <div
                  className={classes.headerSidebar}
                  style={{ backgroundColor: customization?.background_color }}
                >
                  <RouterLink to="/" className={classes.linkLogo}>
                    <img
                      alt="Logo"
                      src={customization?.logo?.url}
                      className={classes.logo}
                    />
                  </RouterLink>
                </div>
              )}
              <Profile />
              <Divider className={classes.divider} />
              <SidebarNav className={classes.nav} pages={currentRoutes.filter(route => !route.hidden)} />
            </div>
          )}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <Toolbar />
          {location.pathname.includes('/lecture') ? (
            <div className={classes.toolbar}>
              <SidebarNav className={classes.nav} pages={lectureItems} />
            </div>
          ) : (
            <div className={classes.toolbar}>
              <Profile />
              <Divider className={classes.divider} />
              <SidebarNav className={classes.nav} pages={currentRoutes.filter(route => !route.hidden)} />
            </div>
          )}
        </Drawer>
      </Hidden>
    </nav>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default Sidebar;
