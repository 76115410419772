import * as React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RouteWithLayout } from '../../components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Learn as LearnLayout,
} from '../../layouts';

import {
  DashboardIndex as DashboardIndexView,
  CompanyIndex as CompanyIndexView,
  CompanyCreate as CompanyCreateView,
  CompanyEdit as CompanyEditView,
  UserIndex as UserIndexView,
  UserCreateMassive as UserCreateMassiveView,
  UserCreate as UserCreateView,
  UserEdit as UserEditView,
  UserEditProfile as UserEditProfileView,
  UserProfile as UserProfileView,
  UserSolicitationIndex as UserSolicitationIndexView,
  UserSolicitationEdit as UserSolicitationEditView,
  CategoryIndex as CategoryIndexView,
  LibraryIndex as LibraryIndexView,
  LibraryCreate as LibraryCreateView,
  LibraryEdit as LibraryEditView,
  CategoryCreate as CategoryCreateView,
  CategoryEdit as CategoryEditView,
  ContractIndex as ContractIndexView,
  ContractCreate as ContractCreateView,
  ContractEdit as ContractEditView,
  SubcategoryIndex as SubcategoryIndexView,
  SubcategoryCreate as SubcategoryCreateView,
  SubcategoryEdit as SubcategoryEditView,
  GroupIndex as GroupUserIndexView,
  GroupCreate as GroupUserCreateView,
  GroupEdit as GroupEditView,
  NotFound as NotFoundView,
  Signin as SigninView,
  Signup as SignupView,
  RedefinePassword as RedefinePasswordView,
  RequestPassword as RequestPasswordView,
  RequestAccess as RequestAccessView,
  SettingGeneral as SettingGeneralView,
  SettingEmail as SettingEmailView,
  SettingCertificate as SettingCertificateView,
  SettingCustomFields as SettingCustomFieldsView,
  SettingService as SettingServiceView,
  CourseIndex as CourseIndexView,
  CourseManage as CourseManageView,
  CourseSetting as CourseSettingView,
  CourseCreate as CourseCreateView,
  ClassroomCourseIndex as ClassroomCourseIndexView,
  ClassroomCourseManage as ClassroomCourseManageView,
  ClassroomCourseSetting as ClassroomCourseSettingView,
  ClassroomCourseCreate as ClassroomCourseCreateView,
  ClassroomCourseLists as ClassroomCourseListsView,
  TrailIndex as TrailIndexView,
  TrailCreate as TrailCreateView,
  TrailEdit as TrailEditView,
  TagIndex as TagIndexView,
  TagCreate as TagCreateView,
  TagEdit as TagEditView,
  LessonIndex as LessonIndexView,
  LessonCreate as LessonCreateView,
  LessonEdit as LessonEditView,
  ModuleCreate as ModuleCreateView,
  ClassroomIndex as ClassroomIndexView,
  ClassroomCreate as ClassroomCreateView,
  ClassroomEdit as ClassroomEditView,
  AttendanceCreate as AttendanceCreateView,
  AttendancesIndex as AttendancesIndexView,
  AttendanceShow as AttendanceShowView,
  NotificationList as NotificationListView,
  ChangePassword as ChangePasswordView,
  SettingNewEmail,
  //Reports
  ReportCompanyUsersIndex as ReportCompanyUsersIndexView,
  ReportTrailsIndex as ReportCompanyTrailsIndexView,
  ReportAttendanceIndex as ReportCompanyAttendanceIndexView,
  ReportLGPDIndex as ReportLGPDIndexView,
  ReportAcademicIndex as ReportAcademicIndexView,
  LearnIndex as LearnIndexView,
  LearnContent as LearnContentView,
  // AllCoursesList as AllCoursesList,
} from 'views';
import { Customization } from 'views/Customization';
import ContentProduction from 'views/ContentProduction';
import AllCoursesList from 'views/AllCoursesList';
import { SalesView } from 'views/Sales';
import { HelpView } from 'views/help';
import TeamsView from 'views/Teams';
import FeaturedCourseView from 'views/FeaturedCourse/index';
import CourseRegistration from 'views/CourseRegistration';
import CompanyCustomization from 'views/Setting/CompanyCustomization';
import ExamComponent from 'views/Exams/Evaluation';
import PublicCertificateView from 'views/Certificate/PublicCertificateView';
import AdminCertificates from 'views/Certificate/AdminCertificates';
import { useSelector } from 'react-redux';
import AdminDashboardView from 'views/AdminDashboard';
import GamificationPointsManagment from 'views/Gamification/Admin/PointsManagment';
import GamificationSettings from 'views/Gamification/Admin/Settings';
import GamificationRewards from 'views/Gamification/Admin/Rewards';
import GamificationReports from 'views/Gamification/Admin/Reports';
import GamificationRanking from 'views/Gamification/Admin/Ranking';

export default function AdminRouter() {
  const { shouldChangePassword } = useSelector((state) => state.auth);

  if (shouldChangePassword) {
    return (
      <Switch>
        <Route path="/change-password">
          <ChangePasswordView />
        </Route>
        <Route path="*">
          <Redirect to="/change-password" />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <RouteWithLayout
        component={SigninView}
        exact
        layout={MinimalLayout}
        path="/"
      />

      <RouteWithLayout
        component={SignupView}
        exact
        layout={MinimalLayout}
        path="/register"
      />
      <RouteWithLayout
        component={RequestAccessView}
        exact
        layout={MinimalLayout}
        path="/solicitation-access"
      />
      <RouteWithLayout
        component={RequestPasswordView}
        exact
        layout={MinimalLayout}
        path="/solicitation-password"
      />
      <RouteWithLayout
        component={RedefinePasswordView}
        exact
        layout={MinimalLayout}
        path="/password/:token"
      />
      {/* Route without Login */}
      <RouteWithLayout
        component={CourseRegistration}
        exact
        layout={MinimalLayout}
        path="/course/:id/enroll"
      />
      <RouteWithLayout
        component={DashboardIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={GamificationSettings}
        exact
        isPrivate
        layout={MainLayout}
        path="/gamification/settings"
      />
      <RouteWithLayout
        component={GamificationPointsManagment}
        exact
        isPrivate
        layout={MainLayout}
        path="/gamification/points"
      />
      <RouteWithLayout
        component={GamificationRewards}
        exact
        isPrivate
        layout={MainLayout}
        path="/gamification/rewards"
      />
      <RouteWithLayout
        component={GamificationReports}
        exact
        isPrivate
        layout={MainLayout}
        path="/gamification/reports"
      />
      <RouteWithLayout
        component={GamificationRanking}
        exact
        isPrivate
        layout={MainLayout}
        path="/gamification/ranking"
      />
      <RouteWithLayout
        component={CompanyIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/companies"
      />
      <RouteWithLayout
        component={CompanyCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/companies/create"
      />
      <RouteWithLayout
        component={CompanyEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/companies/:id/edit"
      />
      <RouteWithLayout
        component={SettingGeneralView}
        exact
        isPrivate
        layout={MainLayout}
        path="/settings/general"
      />
      <RouteWithLayout
        component={SettingEmailView}
        exact
        isPrivate
        layout={MainLayout}
        path="/settings/email"
      />
      <RouteWithLayout
        component={CompanyCustomization}
        exact
        isPrivate
        layout={MainLayout}
        path="/settings/company-customization"
      />
      <RouteWithLayout
        component={SettingNewEmail}
        exact
        isPrivate
        layout={MainLayout}
        path="/settings/email/:id/edit"
      />
      <RouteWithLayout
        component={SettingCertificateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/settings/certificate"
      />
      <RouteWithLayout
        component={SettingCustomFieldsView}
        exact
        isPrivate
        layout={MainLayout}
        path="/settings/custom-fields"
      />
      <RouteWithLayout
        component={FeaturedCourseView}
        exact
        isPrivate
        layout={MainLayout}
        path="/settings/featured-courses"
      />
      <RouteWithLayout
        component={SettingServiceView}
        exact
        isPrivate
        layout={MainLayout}
        path="/settings/service"
      />
      <RouteWithLayout
        component={UserIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={UserCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/users/create"
      />
      <RouteWithLayout
        component={UserCreateMassiveView}
        exact
        isPrivate
        layout={MainLayout}
        path="/users/create-massive"
      />
      <RouteWithLayout
        component={UserEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/users/:id/edit"
      />
      <RouteWithLayout
        component={UserEditProfileView}
        exact
        isPrivate
        layout={MainLayout}
        path="/users/edit-profile"
      />
      <RouteWithLayout
        component={UserProfileView}
        exact
        isPrivate
        layout={MainLayout}
        path="/users/:id/profile"
      />
      <RouteWithLayout
        component={UserSolicitationIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/user-solicitations"
      />
      <RouteWithLayout
        component={ExamComponent}
        exact
        isPrivate
        layout={MainLayout}
        path="/lesson-exam/:id_lesson_content/:id_lesson"
      />

      <RouteWithLayout
        component={UserSolicitationEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/user-solicitations/:id/edit"
      />
      <RouteWithLayout
        component={LibraryIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/library"
      /><RouteWithLayout
        component={LibraryCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/library/create"
      />
      <RouteWithLayout
        component={LibraryEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/library/:id/edit"
      />
      <RouteWithLayout
        component={CategoryIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/categories"
      />
      <RouteWithLayout
        component={CategoryCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/categories/create"
      />
      <RouteWithLayout
        component={CategoryEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/categories/:id/edit"
      />
      <RouteWithLayout
        component={SubcategoryIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/subcategories"
      />
      <RouteWithLayout
        component={SubcategoryCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/subcategories/create"
      />
      <RouteWithLayout
        component={SubcategoryEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/subcategories/:id/edit"
      />
      <RouteWithLayout
        component={GroupUserIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/groups"
      />
      <RouteWithLayout
        component={GroupUserCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/groups/create"
      />
      <RouteWithLayout
        component={GroupEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/groups/:id/edit"
      />
      <RouteWithLayout
        component={CourseIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/courses"
      />
      <RouteWithLayout
        component={ClassroomCourseIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/classroomcourses"
      />
      <RouteWithLayout
        component={LessonIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/courses/:id/lessons"
      />
      <RouteWithLayout
        component={CourseSettingView}
        exact
        isPrivate
        layout={MainLayout}
        path="/courses/:id/config"
      />
      <RouteWithLayout
        component={ClassroomCourseListsView}
        exact
        isPrivate
        layout={MainLayout}
        path="/classroomcourses/:id/lists"
      />
      <RouteWithLayout
        component={ClassroomCourseSettingView}
        exact
        isPrivate
        layout={MainLayout}
        path="/classroomcourses/:id/config"
      />
      <RouteWithLayout
        component={CourseManageView}
        exact
        isPrivate
        layout={MainLayout}
        path="/courses/manage"
      />
      <RouteWithLayout
        component={ClassroomCourseManageView}
        exact
        isPrivate
        layout={MainLayout}
        path="/classroomcourses/manage"
      />
      <RouteWithLayout
        component={CourseCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/courses/create"
      />
      <RouteWithLayout
        component={ClassroomCourseCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/classroomcourses/create"
      />
      <RouteWithLayout
        component={PublicCertificateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/certificates/public/:id_course/:id_user"
      />
      <RouteWithLayout
        component={AdminCertificates}
        exact
        isPrivate
        layout={MainLayout}
        path="/certificates/:id_user/:id_course"
      />
      {/* <RouteWithLayout
        component={TagIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/tags"
      /> */}
      <RouteWithLayout
        component={TagCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/tags/create"
      />
      <RouteWithLayout
        component={TagEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/tags/:id/edit"
      />
      <RouteWithLayout
        component={TrailIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/trails"
      />
      <RouteWithLayout
        component={TrailCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/trails/create"
      />
      <RouteWithLayout
        component={TrailEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/trails/:id/edit"
      />
      <RouteWithLayout
        component={LessonCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/lessons/:courseId/create"
      />
      <RouteWithLayout
        component={LessonEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/lessons/:id/edit"
      />
      <RouteWithLayout
        component={ModuleCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/module/:courseId/create"
      />
      <RouteWithLayout
        component={ModuleCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/course/:courseId/module/:id/edit"
      />
      {/* Reports */}
      <RouteWithLayout
        component={ReportCompanyUsersIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/reports/companies-users"
      />
      <RouteWithLayout
        component={ReportCompanyTrailsIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/reports/companies-trails"
      />
      <RouteWithLayout
        component={ReportCompanyAttendanceIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/reports/companies-attendance"
      />
      <RouteWithLayout
        component={ReportLGPDIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/reports/lgpd"
      />
      <RouteWithLayout
        component={ReportAcademicIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/reports/academic"
      />
      <RouteWithLayout
        component={ClassroomIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/classroom"
      />
      <RouteWithLayout
        component={ClassroomCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/classroom/create"
      />
      <RouteWithLayout
        component={ClassroomEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/classroom/:id/edit"
      />
      <RouteWithLayout
        component={AttendancesIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/attendance"
      />
      <RouteWithLayout
        component={AttendanceShowView}
        exact
        isPrivate
        layout={MainLayout}
        path="/attendance/:id/show"
      />
      <RouteWithLayout
        component={AttendanceCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/attendance/create"
      />
      <RouteWithLayout
        component={NotificationListView}
        exact
        isPrivate
        layout={MainLayout}
        path="/notification"
      />
      <RouteWithLayout
        component={LearnIndexView}
        exact
        isPrivate
        layout={LearnLayout}
        path="/course/:id/learn"
      />
      <RouteWithLayout
        component={LearnContentView}
        exact
        isPrivate
        layout={LearnLayout}
        path="/course/:id/learn/:learnId"
      />
      <RouteWithLayout
        component={Customization}
        exact
        isPrivate
        layout={MainLayout}
        path="/customization"
      />
      <RouteWithLayout
        component={TeamsView}
        exact
        isPrivate
        layout={MainLayout}
        path="/teams"
      />
      <RouteWithLayout
        component={AdminDashboardView}
        exact
        isPrivate
        layout={MainLayout}
        path="/admin-dashboard"
      />
      <RouteWithLayout
        component={HelpView}
        exact
        isPrivate
        layout={MainLayout}
        path="/help"
      />
      <RouteWithLayout
        component={ContractIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/settings/lgpd"
      />
      <RouteWithLayout
        component={ContentProduction}
        exact
        isPrivate
        layout={MainLayout}
        path="/production"
      />
      <RouteWithLayout
        component={AllCoursesList}
        exact
        isPrivate
        layout={MainLayout}
        path="/all-courses"
      />
      <RouteWithLayout
        component={ContractCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/contracts/create"
      />
      <RouteWithLayout
        component={ContractEditView}
        exact
        isPrivate
        layout={MainLayout}
        path="/contracts/:id/edit"
      />
      {/* Not Found View */}
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
}
