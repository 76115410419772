import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GamificationData from 'services/dataService/GamificationData';
import { useStyles } from '../styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TimelineIcon from '@material-ui/icons/Timeline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import palette from 'theme/palette';

const Score = () => {

    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(1);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [rewards, setRewards] = useState([]);
    const { id_company, id } = useSelector((state) => state.auth);

    useEffect(() => {
        setTimeout(() => {
            setFeedbackMessage("");
        }, 2000);
    }, [feedbackMessage]);

    async function handleRescue({
        id_reward,
        total_cost,
        quantity,
        data_rescue
    }) {
        const rescue = await GamificationData.createRescue({
            id_user: id,
            id_rewards: id_reward,
            total_cost: total_cost,
            quantity: quantity,
            data_rescue: data_rescue,
            status_rescue: "Processado"
        });

        const { data: { userRewardsResponse } } = rescue;

        if (userRewardsResponse.id) {
            const transactionGamification = await GamificationData.createTransaction({
                type: "DEBIT",
                amount: userRewardsResponse.total_cost,
                observation: "Resgate recompensa",
                id_user: id
            })
            setFeedbackMessage("Recompensa resgatada com sucesso");
            setTimeout(() => {
                window.location.reload();
            }, 2500);
        }
    }

    const getAllRewards = async () => {
        const { data: { allRewards } } = await GamificationData.getAllRewards({ id_company: id_company });
        setRewards(allRewards);
    }

    useEffect(() => {
        getAllRewards();
    }, []);

    return (
        <div className={classes.scoreViewContainer}>
            <div className={classes.scoreViewHeader}>
                <div className={classes.scoreHeaderButtonContainer} onClick={() => setActiveTab(1)}>
                    <LocalOfferIcon />
                    <a href='#'>Recompensas</a>
                </div>
                <div className={classes.scoreHeaderButtonContainer} onClick={() => setActiveTab(2)}>
                    <AssessmentIcon />
                    <a href='#'>Extrato</a>
                </div>
                <div className={classes.scoreHeaderButtonContainer} onClick={() => setActiveTab(3)}>
                    <TimelineIcon />
                    <a href='#'>Ranking</a>
                </div>
            </div>
            <div className={classes.scoreViewContent}>
                {
                    activeTab === 1 && (
                        <div>
                            {
                                feedbackMessage && (
                                    <div className={classes.modal}>
                                        <div className={classes.feedbackMessageRewardsContainer}>
                                            <IoIosCheckmarkCircleOutline size={80} color={palette.primary.main} />
                                            <p>{feedbackMessage}</p>
                                        </div>
                                    </div>
                                )
                            }
                            <div className={classes.rewardTitle}>
                                <h4>Vitrine de Recompensas</h4>
                            </div>
                            <div className={classes.vitrineContainer}>
                                {
                                    rewards.length > 0 && rewards.map((reward, index) => (

                                        <div className={classes.rewardBox}>
                                            <div className={classes.rewardBoxImage}></div>
                                            <div className={classes.rewardBoxContent}>
                                                <h3>{reward.name}</h3>
                                                <p>{reward.description}</p>
                                                <div className={classes.rewardBoxContentBottom}>
                                                    <p className={classes.rewardBoxCoins}><MonetizationOnIcon /> {reward.cost} DreamCoins</p>
                                                    <a className={classes.rewardBoxButton} onClick={() => handleRescue({
                                                        id_reward: reward.id,
                                                        total_cost: reward.cost,
                                                        quantity: 1,
                                                        data_rescue: new Date()

                                                    })}>Resgatar</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                {
                    activeTab === 2 && (
                        <div>
                            <div>
                                <h4>Extrato</h4>
                            </div>
                            <div>

                            </div>
                        </div>
                    )
                }
                {
                    activeTab === 3 && (
                        <div>
                            <div>
                                <h4>Ranking</h4>
                            </div>
                            <div>

                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Score;