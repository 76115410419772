import api from '../api';

class GamificationData {
  createTransaction(data) {
    if (data.amount && typeof data.amount === 'string') {
      data.amount = parseInt(data.amount, 10); // Converte para inteiro na base decimal
    }
    return api.post('/gamification-transactions', data);
  }

  getTotalCoinsByUserId(userId) {
    return api.post('/user-coins', userId);
  }

  getAllUserCoins() {
    return api.get('/user-coins');
  }

  getAllTransactions(userId) {
    return api.post('/transactions', userId);
  }

  getAllRewards(idCompany) {
    return api.post('/all-rewards', idCompany);
  }

  createRescue(reward) {
    return api.post('/user-rewards', reward);
  }

}

export default new GamificationData();
